import React from "react"

const ImagePicker = ({ pageContext }) => {
  const FeaturedImage = pageContext && pageContext.featuredImage
  const FullWidthImage =
    pageContext &&
    pageContext.full_width_image &&
    pageContext.full_width_image.fullWidthImage

  if (FeaturedImage) {
    return (
      <img
        className="mb-4"
        alt={FeaturedImage && FeaturedImage.altText}
        srcSet={FeaturedImage && FeaturedImage.srcSet}
        src={FeaturedImage && FeaturedImage.sourceUrl}
      />
    )
  } else if (FullWidthImage) {
    return (
      <img
        className="mb-4"
        alt={FullWidthImage && FullWidthImage.altText}
        srcSet={FullWidthImage && FullWidthImage.srcSet}
        src={FullWidthImage && FullWidthImage.sourceUrl}
      />
    )
  } else {
    return null
  }
}

export default ImagePicker
