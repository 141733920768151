import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImagePicker from "../../components/ImagePicker"

const SinglePage = ({ pageContext }) => {
  const { content, slug, title } = pageContext

  return (
    <Layout>
      <SEO
        title={pageContext.title}
        description="Welcome to Moore's Sewing Centers"
      />
      <Container className="mt-5">
        <h1
          className="section-header"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Row>
          <Col>
            <ImagePicker pageContext={pageContext} />
          </Col>
        </Row>

        <Row>
          <Col
            // style={{ fontSize: 20 }}
          >
            {" "}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SinglePage
